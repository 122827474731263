<template>
  <div class="printInvoice">
    <div class="form-item-button gray padding-horizontal-12 print-button" v-if="!isPrint" @click="printFn">
      {{ t('deliveryManageDetail.printStockList') }}
    </div>
    <!--startprint-->
    <div class="printInvoice-header">
      {{ t('deliveryManageDetail.stockList') }}
      <img id="barcode" />
    </div>
    <div class="printInvoice-body">
      <!-- <div class="centerView"> -->
      <a-space direction="vertical" style="width: 100%; margin-bottom: 20px">
        <a-row>
          <a-col :span="10">
            <span class="printInvoice-icon-item-key">{{ t('logoName') }}：</span>
            <span class="printInvoice-icon-item-value">{{ $store.getters.userInfo.businessBo && $store.getters.userInfo.businessBo.name }}</span>
          </a-col>
          <a-col :span="7">
            <span class="printInvoice-icon-item-key">{{ t('deliveryManageDetail.purchaseAmount') }}：</span>
            <span class="printInvoice-icon-item-value">{{ detailData.orderSn }}</span>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="10">
            <span class="printInvoice-icon-item-key">{{ t('deliveryManage.table.deliveryData') }}：</span>
            <span class="printInvoice-icon-item-value">{{ detailData.dissentReceiptAt || '--' }}</span>
          </a-col>
          <a-col :span="7" v-if="isPrint">
            <span class="printInvoice-icon-item-key">{{ t('deliveryManageDetail.printTime') }}：</span>
            <span class="printInvoice-icon-item-value">{{ moment().format('YYYY-MM-DD HH:mm:ss') }}</span>
          </a-col>
        </a-row>
        <a-row>
          <!-- <a-col :span="10">
              <img id="barcode" />
            </a-col> -->
          <a-col :span="10">
            <a-space direction="vertical">
              <div>
                <span class="printInvoice-icon-item-key">{{ t('deliveryManage.table.receivingWarehouseName') }}：</span>
                <span class="printInvoice-icon-item-value">{{ detailData.receivingWarehouseName }}</span>
              </div>
              <div>
                <span class="printInvoice-icon-item-key">{{ t('addressInfo.receiver') }}：</span>
                <span class="printInvoice-icon-item-value">{{ detailData.receiverName }}</span>
              </div>
              <div>
                <span class="printInvoice-icon-item-key">{{ t('addressInfo.address') }}：</span>
                <span class="printInvoice-icon-item-value">{{ detailData.receiverAddress }}</span>
              </div>
            </a-space>
          </a-col>
          <a-col :span="7">
            <a-space direction="vertical">
              <div>
                <span class="printInvoice-icon-item-key">{{ t('orderManageDetail.DeliveryTable.table.printTimes') }}：</span>
                <span class="printInvoice-icon-item-value">{{ moment().format('YYYY-MM-DD HH:mm:ss.SSS') }}</span>
              </div>
              <div>
                <span class="printInvoice-icon-item-key">{{ t('orderManageDetail.DeliveryTable.table.shippedQuantity') }}：</span>
                <span class="printInvoice-icon-item-value">{{ detailData.dissentQuantity - detailData.deliveryQuantity }}</span>
              </div>
              <div>
                <span class="printInvoice-icon-item-key">{{ t('addressInfo.phone') }}：</span>
                <span class="printInvoice-icon-item-value">{{ detailData.receiverPhone }}</span>
              </div>
            </a-space>
          </a-col>
        </a-row>
        <!-- <a-row>
            <a-col :span="24">备注 : 47401</a-col>
          </a-row> -->
      </a-space>
      <!-- </div> -->
      <!-- <div class="centerView"> -->
      <a-table
        :dataSource="dataSource.data"
        :columns="columns"
        :pagination="false"
        row-key="id"
        size="small"
        bordered
        class="ant-table-striped"
        :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
        :scroll="{ x: 1000 }"
      >
        <template #productPicTemplate="{ text }">
          <ImgGallery :urls="[text]" :img-width="39" :img-height="52" :show-img-num="1" :show-more="false" />
        </template>
        <template #manufacturingNo="{ text }"
          ><span style="color: #000; font-size: 18px; font-weight: 900">{{ text }}</span></template
        >
        <template #productParams="{ text }"> {{ (JSON.parse(text || '{}') || {}).color_name }} / {{ (JSON.parse(text || '{}') || {}).size_name }} </template>
        <template #deliveryQuantity="{ record }"
          ><span style="color: #000; font-size: 20px; font-weight: 900">{{ record.dissentQuantity - record.deliveryQuantity }}</span></template
        >
        <template #verifiedQuantity> </template>
      </a-table>
      <pagination mt lable="" @change="onPageChange" :data="dataSource" @sizeChange="onSizeChange"></pagination>
      <!-- </div> -->
    </div>
    <!--endprint-->
  </div>
</template>

<script setup>
/* eslint-disable */
import pagination from '@/components/pagination'
import { ref, reactive, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { transformColumnsI18n } from '@/language'
import ImgGallery from '@/views/supplier/components/ImgGallery'
import { initialColumns } from './config/printDeliverTableConfig'

import JsBarcode from 'jsbarcode'
import moment from 'moment'

import { getDeliveryManageDetail } from '@/api/supplier/orderManagement/deliveryManage'
import { getOrderManageShippingDetail, orderDetailPageList } from '@/api/supplier/orderManagement/orderManage'

const { t, locale } = useI18n()
const route = useRoute()
const id = route.query.id
const detailData = ref({})
const dataSource = ref({})
const getDeliveryManageDetailFn = () => {
  getOrderManageShippingDetail({ id }).then((res) => {
    if (res.code == 200) {
      detailData.value = res.data
      dataSource.value = res.data.purchaseOrderDetailList
      getBarcode(res.data.orderSn)
      getTableData()
    }
  })
}
getDeliveryManageDetailFn()

function getBarcode(number) {
  JsBarcode('#barcode', String(number), {
    // format: 'pharmacode', //条形码的格式
    // lineColor: '#0aa', //线条颜色
    // width: 4, //线宽
    height: 60, //条码高度
    displayValue: true, //是否显示文字信息
  })
}

const columns = ref(transformColumnsI18n(initialColumns))
watch(locale, () => {
  columns.value = transformColumnsI18n(initialColumns)
})

//发货单表格数据分页相关逻辑
const page = ref({
  page: 1,
  size: 10,
})
function getTableData() {
  let payload = {
    purchaseOrderSn: detailData.value.orderSn,
    canDelivery: true,
    ...page.value,
  }
  orderDetailPageList(payload)
    .then((res) => {
      console.log(res, 'res-123123')
      if (res.code === 200) {
        dataSource.value = res.data
      }
    })
    .catch(() => {})
}
const onPageChange = (e) => {
  page.value.page = e
  getTableData()
}
const onSizeChange = (e) => {
  page.value.size = e.pageSize
  page.value.page = 1
  getTableData()
}

const isPrint = ref(false)
function printFn() {
  isPrint.value = true
  setTimeout(() => {
    window.print()
  })
  setTimeout(() => {
    isPrint.value = false
  })
}
</script>

<style lang="less">
.printInvoice {
  width: 1100px;
  margin: 0 auto;
  position: relative;
  padding: 20px;

  .print-button {
    position: absolute;
    right: -100px;
    top: 20px;
    z-index: 1;
  }
  .printInvoice-header {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    line-height: 2;
    width: 100%;
    padding: 0 30px 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .ant-table .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
    background-color: #fff !important;
  }
  .ant-table .ant-table-content .ant-table-body table {
    border-radius: 0px !important;
    .ant-table-thead {
      & > tr > th {
        padding: 12px 8px !important;
        // border-bottom: none !important;
      }

      & > tr:first-child > th:first-child {
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
      }

      & > tr:last-child > th:last-child {
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
      }
    }
    .ant-table-tbody > tr > td {
      padding: 10px 8px !important;
    }
  }
  .ant-table-thead > tr > th {
    background-color: #fff !important;
  }
  .printInvoice-body {
    .printInvoice-icon-item-key {
      display: inline-block;
      min-width: 70px;
      text-align: justify;
    }
  }
}
</style>
