<template>
  <div
    class="pagination flexS boxS"
    v-if="data && data.data && data.data.length != 0 && data.totalPage"
    :class="{ mt: mt, mb: mb }"
  >
    <!-- <span> {{ lable }}总数：{{ data.total.toLocaleString() }} </span> -->
    <div class="pagePain">
      {{ t('components.Pagination.total') }}
      <span>{{ data.totalPage }}</span>
      {{ t('components.Pagination.page') }} /
      <span>{{ data.total.toLocaleString() }}</span>
      {{ t('components.Pagination.datas') }}
    </div>
    <a-pagination
      show-size-changer
      @change="onPageChange"
      :current="data.page"
      :pageSize="data.size"
      :total="data.total"
      @showSizeChange="onShowSizeChange"
    />
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
export default {
  name: 'Pagination',
  props: {
    lable: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      default: null,
    },
    mb: {
      type: Boolean,
      default: false,
    },
    mt: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change', 'sizeChange'],
  setup(props, context) {
    const { t } = useI18n();
    function onPageChange(e) {
      context.emit('change', e);
    }
    function onShowSizeChange(current, pageSize) {
      context.emit('sizeChange', {
        current,
        pageSize,
      });
    }
    return {
      t,
      onPageChange,
      onShowSizeChange,
    };
  },
};
</script>

<style scoped lang="less">
.mt {
  margin-top: 15px;
}
.mb {
  margin-bottom: 15px;
}
.pagination {
  width: 100%;
  span {
    font-size: 16px;
    color: black;
  }
}
.boxS {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.pagePain {
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: #5d5758;
  margin-right: 20px;
  span {
    font-size: 12px;
    line-height: 18px;
    color: #ff6700;
  }
}
</style>
