import request from '@/utils/request'

/**
 * 分页获取订单列表
 * http://yapi.wahool.com/project/29/interface/api/6180
 * 邢心平
 * @param data
 * @returns {Promise | Promise<any>}
 */
export function getOrderManageTableData(data = {}) {
  return request.GET('/supplier/order/page', data)
}

/**
 * 订单详情
 * http://yapi.wahool.com/project/29/interface/api/6187
 * 邢心平
 * @param data
 * @returns {Promise | Promise<any>}
 */
export function getOrderManageDetail(data = {}) {
  return request.GET('/supplier/order/get', data)
}

/**
 * 接单
 * http://yapi.wahool.com/project/29/interface/api/6194
 * 邢心平
 * @param data
 * @returns {Promise | Promise<any>}
 */
export function acceptOrder(data = {}) {
  return request.POST('/supplier/order/accept', data)
}

/**
 * 异议
 * http://yapi.wahool.com/project/29/interface/api/6201
 * 邢心平
 * @param data
 * @returns {Promise | Promise<any>}
 */
export function dissentOrder(data = {}) {
  return request.POST(`/supplier/order/dissent`, data)
}

/**
 * 订单详情导出
 * http://yapi.wahool.com/project/29/interface/api/6250
 * 邢心平
 * @param data
 * @returns {Promise | Promise<any>}
 */
export function exportOrder(data = {}) {
  return request.GET('/supplier/order/export', data, { responseType: 'blob' })
}

/**
 * 订单发货详情
 * http://yapi.wahool.com/project/29/interface/api/6257
 * 邢心平
 * @param data
 * @returns {Promise | Promise<any>}
 */
export function getOrderManageShippingDetail(data = {}) {
  return request.GET('/supplier/order/getShipping', data)
}

/**
 * 创建发货单
 * http://yapi.wahool.com/project/29/interface/api/6306
 * 谢高仁
 * @param data
 * @returns {Promise | Promise<any>}
 */
export function createShipping(data = {}) {
  return request.POST(`/supplier/shipping/create`, data)
}

/**
 * 导出待发明细
 * http://yapi.wahool.com/project/29/interface/api/6383
 * 邢心平
 * @param data
 * @returns {Promise | Promise<any>}
 */
export function exportShippingDetail(data = {}) {
  return request.GET('/supplier/order/exportShipping', data, { responseType: 'blob' })
}

/**
 * 获取日志列表
 * http://yapi.wahool.com/project/29/interface/api/6397
 * 邢心平
 * @param data
 * @returns {Promise | Promise<any>}
 */
export function getOperationLog(data = {}) {
  return request.GET('/supplier/operation-log/list', data)
}

/**
 * 获取订单个数
 * http://yapi.wahool.com/project/29/interface/api/6502
 * 邢心平
 * @param data
 * @returns {Promise | Promise<any>}
 */
export function getOrderManageTotalsData(data = {}) {
  return request.GET('/supplier/order/listCount', data)
}

//发货单明细
export function orderDetailPageList(data = {}) {
  return request.GET('/supplier/purchase-order-detail/page', data)
}
