export const initialColumns = [
  {
    title: 'deliveryManageDetail.table.productPicTemplate',
    dataIndex: 'productPicTemplate',
    key: 'productPicTemplate',
    width: 30,
    slots: { customRender: 'productPicTemplate' },
  },
  {
    title: 'deliveryManageDetail.table.manufacturingNo',
    dataIndex: 'manufacturingNo',
    key: 'manufacturingNo',
    slots: { customRender: 'manufacturingNo' },
    width: 140,
  },
  {
    title: 'deliveryManageDetail.table.sku',
    dataIndex: 'sku',
    key: 'sku',
    width: 70,
    // slots: { customRender: 'sku' },
  },
  {
    title: 'deliveryManageDetail.table.productName',
    dataIndex: 'productName',
    key: 'productName',
    width: 120,
  },
  {
    title: 'deliveryManageDetail.table.productParams',
    dataIndex: 'productParams',
    key: 'productParams',
    width: 80,
    slots: { customRender: 'productParams' },
  },
  {
    title: 'deliveryManageDetail.table.quantityToShipped',
    dataIndex: 'deliveryQuantity',
    key: 'deliveryQuantity',
    align: 'center',
    slots: { customRender: 'deliveryQuantity' },
    width: 50,
  },
  {
    title: 'orderManageDetail.DeliveryTable.table.verifiedQuantity',
    dataIndex: 'verifiedQuantity',
    key: 'verifiedQuantity',
    align: 'center',
    slots: { customRender: 'verifiedQuantity' },
    width: 50,
  },
]
